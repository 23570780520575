import { useNavigate } from "react-router-dom";
import { useState, type ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";

import { useMutation } from "util/graphql/mutation";
import { captureException } from "util/exception";
import { TRANSACTION_PATH } from "util/routes";
import {
  Feature,
  TransactionMembershipRoles,
  OrganizationTypeEnum,
  type SigningScheduleTypes,
} from "graphql_globals";
import { segmentTrack } from "util/segment";
import { newPathWithPreservedSearchParams } from "util/location";
import { EVENT } from "constants/analytics";
import { isGraphQLError } from "util/graphql/query";
import { getEventFromFormV3, pendoTrack } from "util/pendo";

import SendTransactionMutation from "./send_transaction_mutation.graphql";
import SendTransactionToClosingOps from "./send_organization_transaction_to_closing_ops_mutation.graphql";
import SendTransactionToTitleAgency from "./send_organization_transaction_to_title_agency_mutation.graphql";
import UpdateTransactionMutation, {
  type UpdateTransaction_updateOrganizationTransactionV2_organizationTransaction as UpdatedTransaction,
} from "./update_transaction_mutation.graphql";
import { type Config, ALL_CONFIGS, getConfigFromVariant } from "./config";
import {
  useOnSendErrorHandler,
  ON_SEND_MODAL_ERRORS,
  ON_SEND_MODAL_ERROR_CODES,
  NO_SIGNATURE_FLAG_ERROR,
} from "./validations/on_send";
import { useValidateBeforeSend } from "./validations/pre_send";
import { useValidateBeforeSave } from "./validations/pre_save";
import {
  type Transaction,
  type Organization,
  type Viewer,
  type FormValues,
  isPlaceOrder,
  isPerTransactionPlaceOrderEnabled,
} from "./form";
import { getMortageTransactionConfig } from "./config/mortgage_config_util";
import { TRANSACTION_DETAILS_SECTION } from "./sections/transaction_details";
import { CUSTOM_EMAIL_SECTION } from "./sections/custom_email";
import { NST_PAYMENT_SECTION } from "./sections/nst_payment";
import { ORG_PAYMENT_SECTION } from "./sections/org_payment";
import { RECIPIENT_DETAILS_SECTION } from "./sections/recipient_details";
import { ADDITIONAL_CONTACTS_SECTION } from "./sections/recipient_details/additional_contacts";
import { SIGNING_DETAILS_SECTION } from "./sections/signing_details";
import { canSendToTitleAgency } from "./real_estate/util";

const MESSAGES = defineMessages({
  genericError: {
    id: "7d8cad82-b058-4f52-ae49-432e68508d57",
    defaultMessage: "Something went wrong",
  },
});

type OnSaveStatus =
  | {
      status: "saved";
      updatedOrganizationTransaction: UpdatedTransaction;
    }
  | {
      status: "failed";
    }
  | undefined;

export type SubmitReturn = {
  onSave: ({
    formValues,
    shouldExit,
  }: {
    formValues: FormValues;
    shouldExit: boolean;
  }) => Promise<OnSaveStatus>;
  onSend: ({
    formValues,
    closingOpsOverride,
  }: {
    formValues: FormValues;
    closingOpsOverride?: boolean;
  }) => Promise<unknown>;
  submitErrorModal: ReactNode | null;
  submitErrorMessage: ReactNode | null;
  onSendValidationError: string | null;
};

export type SendTransactionOptions = {
  closingOpsOverride?: boolean;
  recallReason?: string;
};

type SigningSchedule = {
  activationTime: string | null;
  activationTimezone: string | null;
  expiry: string | null;
  expiryTimezone: string | null;
  notaryMeetingTime: string | null;
  notaryMeetingTimezone: string | null;
  scheduleType?: SigningScheduleTypes;
};

export function useOnSubmit(
  transaction: Transaction,
  organization: Organization,
  viewer: Viewer,
  config: Config,
  isSubmitting: boolean,
  closingOpsForceSendToSigner: boolean,
): SubmitReturn {
  const intl = useIntl();
  const navigate = useNavigate();
  const updateTransaction = useMutation(UpdateTransactionMutation);
  const sendTransactionMutateFn = useMutation(SendTransactionMutation);
  const sendTransactionToClosingOps = useMutation(SendTransactionToClosingOps);
  const sendTransactionToTitleAgency = useMutation(SendTransactionToTitleAgency);

  const { validateBeforeSend, presendErrorModal } = useValidateBeforeSend(config);
  const { validateBeforeSave, presaveErrorModal } = useValidateBeforeSave({ transaction, config });
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<SubmitReturn["submitErrorMessage"]>(null);

  const [onSendValidationError, setOnSendValidationError] =
    useState<SubmitReturn["onSendValidationError"]>(null);

  function makeSendTransaction(transaction: Transaction, organization: Organization) {
    return async function sendTransaction({
      closingOpsOverride,
      recallReason,
    }: SendTransactionOptions = {}) {
      const placeOrder = isPlaceOrder({
        placeOrder: transaction.placeOrder,
        transaction,
        config,
      });
      const collabEnabled = canSendToTitleAgency(transaction, organization);

      try {
        if (collabEnabled && !closingOpsForceSendToSigner) {
          await sendTransactionToTitleAgency({
            variables: {
              input: {
                organizationTransactionId: transaction.id,
                closingOpsOverride,
              },
            },
          });
          segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND, {
            organization_transaction_id: transaction.id,
            form: config.id,
            form_version: 3,
          });
        } else if (placeOrder) {
          await sendTransactionToClosingOps({
            variables: {
              input: {
                organizationTransactionId: transaction.id,
                recallReason,
              },
            },
          });
          segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND_CLOSING_OPS, {
            organization_transaction_id: transaction.id,
            form: config.id,
            form_version: 3,
          });
        } else {
          await sendTransactionMutateFn({
            variables: {
              input: { id: transaction.id, closingOpsOverride, recallReason },
            },
          });
          segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND, {
            organization_transaction_id: transaction.id,
            form: config.id,
            form_version: 3,
          });
        }

        const formId =
          transaction.isMortgage && transaction.transactionType
            ? transaction.transactionType
            : config.id;
        pendoTrack(getEventFromFormV3(formId), {
          form: formId,
          formVersion: 3,
        });

        const { path, queryParams } = getPostSendRoute({
          userOrganizationType: organization.organizationType,
          placeAnOrderEnabled: placeOrder,
        });
        navigate(newPathWithPreservedSearchParams(path, queryParams));
      } catch (error) {
        return Promise.reject(error);
      }
      return Promise.resolve();
    };
  }

  const sendTransaction = makeSendTransaction(transaction, organization);
  const { onSendErrorHandler, onsendErrorModal } = useOnSendErrorHandler({ sendTransaction });

  const setErrorMessage = (error: unknown, sendTransactionOptions?: SendTransactionOptions) => {
    let errorMessage = "";
    let errorCode;

    if (isGraphQLError(error)) {
      errorMessage = error.graphQLErrors[0].message;
      errorCode = error.graphQLErrors[0].code;
    } else {
      captureException(error);
    }

    if (
      (errorMessage && ON_SEND_MODAL_ERRORS.includes(errorMessage)) ||
      errorMessage.includes(NO_SIGNATURE_FLAG_ERROR) ||
      (errorCode && ON_SEND_MODAL_ERROR_CODES.includes(errorCode))
    ) {
      onSendErrorHandler(errorMessage, errorCode, sendTransactionOptions);
    } else {
      setSubmitErrorMessage(errorMessage || intl.formatMessage(MESSAGES.genericError));
    }
  };

  const onSave: SubmitReturn["onSave"] = async ({ formValues, shouldExit = false }) => {
    if (isSubmitting) {
      return;
    }
    if (shouldExit) {
      // this is only needed before save AND exit because uploading a doc will trigger a save.
      // once workflows are implemented, we won't need this since txn type/config won't change if no doc is uploaded
      const validationResult = await validateBeforeSave();
      if (validationResult === "failed") {
        return { status: "failed" };
      }
    }

    const getSubmitDataArgs = {
      sectionFormValues: formValues,
      sectionConfig: config,
      transaction,
      organization,
    };

    const customEmailData = CUSTOM_EMAIL_SECTION.getSubmitData(getSubmitDataArgs);
    const nstPaymentData = NST_PAYMENT_SECTION.getSubmitData(getSubmitDataArgs);
    const orgPaymentData = ORG_PAYMENT_SECTION.getSubmitData(getSubmitDataArgs);
    const recipientDetailsData = RECIPIENT_DETAILS_SECTION.getSubmitData(getSubmitDataArgs);
    const signingDetailsData = SIGNING_DETAILS_SECTION.getSubmitData(getSubmitDataArgs);
    const transactionDetailsData = TRANSACTION_DETAILS_SECTION.getSubmitData(getSubmitDataArgs);
    const additionalContactsData = ADDITIONAL_CONTACTS_SECTION.getSubmitData(getSubmitDataArgs);

    // if we want to show transaction templates to nst users that should not have nst payment, then we may need
    // to put this into config and make a separate transaction template for nst transaction "type"
    const paymentStyle = transaction.organization.featureList.includes(
      Feature.CUSTOM_ACCOUNT_PRICING,
    )
      ? "nst"
      : "default";
    // for now, usage of custom email section is not allowed for REAL orgs
    const customEmailInput = transaction.isMortgage
      ? {
          message: signingDetailsData.signerMessage,
        }
      : {
          messageSubject: customEmailData.messageSubject,
          message: customEmailData.message,
          messageSignature: customEmailData.messageSignature,
        };

    const signingSchedule: SigningSchedule = {
      activationTime: signingDetailsData.activationDate,
      activationTimezone: signingDetailsData.activationTimezone,
      expiry: signingDetailsData.expirationDate,
      expiryTimezone: signingDetailsData.expirationTimezone,
      notaryMeetingTime: signingDetailsData.notaryMeetingTime,
      notaryMeetingTimezone: signingDetailsData.notaryMeetingTimezone,
      scheduleType: signingDetailsData.signingScheduleType // TODO: remove sending signing schedule type when v3 fully live BIZ-5581
        ? signingDetailsData.signingScheduleType
        : undefined,
    };

    setSubmitErrorMessage(null);

    try {
      const { data } = await updateTransaction({
        variables: {
          organizationId: organization.id,
          input: {
            id: transaction.id,
            organizationId: organization.id, // used in some places like contacts to set authorOrganizationId
            memberships: [
              {
                organizationId: organization.id,
                role: TransactionMembershipRoles.BUSINESS,
                prices:
                  paymentStyle === "nst"
                    ? {
                        notaverseFirstSeal: nstPaymentData.notaverseFirstSeal,
                        notaverseAdditionalSeal: nstPaymentData.notaverseAdditionalSeal,
                      }
                    : undefined,
              },
            ],
            transaction: {
              name: transactionDetailsData.name,
              transactionType: transactionDetailsData.transactionType,
              secondaryIdRequired: transactionDetailsData.secondaryIdRequired,
              externalId: transactionDetailsData.externalId,
              loanNumber: transactionDetailsData.loanNumber,
              fileNumber: transactionDetailsData.fileNumber,
              streetAddress: transactionDetailsData.streetAddress,
              titleUnderwriterOrgId: transactionDetailsData.titleUnderwriterOrgId,
              recordingLocationId: transactionDetailsData.recordingLocationId || undefined,

              // TODO: entityName: "",

              ...customEmailInput,

              payer: paymentStyle === "nst" ? nstPaymentData.payer : orgPaymentData.payer,

              requiredAuth: recipientDetailsData.requiredAuth,
            },
            customers: recipientDetailsData.customerSigners.map((customerSigner, index) => ({
              id: customerSigner.id,
              firstName: customerSigner.firstName,
              middleName: customerSigner.middleName,
              lastName: customerSigner.lastName,
              email: customerSigner.email,
              // TODO: signatoryTitle: customerSigner.signatoryTitle,
              capacities: customerSigner.capacities.map((capacity) => ({
                id: capacity.id,
                capacity: capacity.capacity,
                capacityType: capacity.capacityType,
                representativeOf: capacity.representativeOf,
              })),
              address: customerSigner.address,
              // Update after [BIZ-5334] is implemented to only use phoneNumber
              // phoneNumber: customerSigner.phoneNumber,
              phone: customerSigner.phone,
              vestingType: customerSigner.vestingType,
              signingRequirement: customerSigner.signingRequirement,
              personallyKnownToNotary:
                index === 0 ? Boolean(signingDetailsData.personallyKnownToNotary) : false,
              proofRequirement: customerSigner.proofRequirement,
              recipientGroup: customerSigner.recipientGroup,
              order: customerSigner.order,
            })),
            signingSchedule,
            witnesses: signingDetailsData.credibleWitnesses,
            notarizeCloserOverride: signingDetailsData.notarizeCloserOverride,
            closerAssigneeId: signingDetailsData.closerAssigneeId,
            // TODO: notary assignment - BIZ-5429
            // TODO: contacts - keep in mind BIZ-5427
            contacts: additionalContactsData.contacts,
            placeOrder: isPerTransactionPlaceOrderEnabled({ transaction, config })
              ? formValues.placeOrder
              : null,
          },
        },
      });

      if (shouldExit) {
        navigate(TRANSACTION_PATH);
      }
      return {
        status: "saved",
        updatedOrganizationTransaction:
          data!.updateOrganizationTransactionV2!.organizationTransaction,
      };
    } catch (error) {
      setErrorMessage(error);

      return { status: "failed" };
    }
  };

  const onSend: SubmitReturn["onSend"] = async ({ formValues }) => {
    setOnSendValidationError(null);

    if (isSubmitting) {
      return;
    }
    const onSaveResult = await onSave({ formValues, shouldExit: false });
    // if validation failed or validation is in flight, don't try to send
    if (onSaveResult?.status !== "saved") {
      return;
    }

    const validationResult = await validateBeforeSend({
      transaction: onSaveResult.updatedOrganizationTransaction, // this makes sure validation uses up to date data after update mutation
      organization,
      viewer,
    });

    if (validationResult.status === "failed") {
      setOnSendValidationError(validationResult.error || null);
      return;
    }

    const sendVariables = { recallReason: validationResult.recallReason };
    const sendTransaction = makeSendTransaction(
      onSaveResult.updatedOrganizationTransaction,
      organization,
    );
    return sendTransaction(sendVariables).catch((error) => {
      setErrorMessage(error, sendVariables);
    });
  };

  return {
    onSave,
    onSend,
    submitErrorModal: presaveErrorModal || presendErrorModal || onsendErrorModal, // we should only ever be showing one since presend depends on presave passing & so forth
    submitErrorMessage,
    onSendValidationError,
  };
}

function getPostSendRoute({
  userOrganizationType,
  placeAnOrderEnabled,
}: {
  userOrganizationType: OrganizationTypeEnum;
  placeAnOrderEnabled: boolean;
}) {
  if (
    // this handles the collab and title initiated as we only redirect to order place screen for title users
    userOrganizationType === OrganizationTypeEnum.TITLE_AGENCY &&
    placeAnOrderEnabled
  ) {
    return {
      path: "/transaction-success",
      queryParams: {
        type: "place-an-order",
      },
    };
  }

  return { path: TRANSACTION_PATH };
}

// long term transaction template will be persisted in db so won't need to derive the config
export function getConfig(
  configId: Config["id"] | null,
  transaction: Transaction,
  organization: Organization,
) {
  if (configId) {
    const config = ALL_CONFIGS.find((config) => config.id === configId);
    if (config) {
      return config;
    }
  }

  if (transaction.isMortgage) {
    return getMortageTransactionConfig(transaction, organization);
  }

  const config = getConfigFromVariant(transaction.transactionVariant);
  if (config) {
    return config;
  }

  throw new Error(
    `Invalid transaction type or variant, got type: ${transaction.transactionType} or variant: ${transaction.transactionVariant}`,
  );
}
